import { Component, OnInit, Inject, TemplateRef, EventEmitter } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ConfirmDialog } from '../../models/confirmDialog.model';
import { RefreshDialog } from 'app/shared/models/refresh/refresh-dialog.model';

@Component({
  selector: 'app-confirmdialog',
  templateUrl: './confirmdialog.component.html',
  styleUrls: ['./confirmdialog.component.scss']
})
export class ConfirmdialogComponent implements OnInit {
  title: string;
  message1: string;
  message2: string;
  customTemplateRef?: TemplateRef<any> = null;
  input: { inputName: string; inputType: string };
  actionButton: string;
  cancelButton: string;
  color: string;
  inputValue: string;
  refresh: RefreshDialog

  displayCheckbox: boolean = false;

  onCheck = new EventEmitter();

  constructor(
    public dialogRef: MatDialogRef<ConfirmdialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: ConfirmDialog
  ) {
    Object.assign(this, data);
    if (this.refresh) {
      this.refresh.costvat = this.refresh.costvat ? this.refresh.costvat * 100 : 0;
      this.refresh.pricevat = this.refresh.pricevat ? this.refresh.pricevat * 100 : 0;
    }
  }

  ngOnInit() {}
  
  submitRefresh(refresh: RefreshDialog){
    if(refresh.marketplace === 'US' || refresh.marketplace === 'CA' || refresh.marketplace === 'MX'){
      refresh.costvat = 0
      refresh.pricevat = 0
    }
    this.dialogRef.close(refresh)
  }

  onCheckboxChange(e) {
    this.onCheck.emit(e.target.checked)
  }

  promptPerc(valType: string) {

    let val = ''
    if (valType === 'price') {
      val = prompt('Price VAT', this.refresh.pricevat.toFixed(2))
      if (val) {
        
        this.refresh.pricevat = parseFloat(val)
      }
    } 
    else if (valType === 'dstFba') {
      val = prompt('DST FBA  Fee', this.refresh.dstFba.toFixed(2))
      if (val) {

        this.refresh.dstFba = parseFloat(val)
      }
    }
    else if (valType === 'dstReferral') {
      val = prompt('DST Referral Fee', this.refresh.dstReferral.toFixed(2))
      if (val) {

        this.refresh.dstReferral = parseFloat(val)
      }
    }
    else {
      val = prompt('Cost VAT', this.refresh.costvat.toFixed(2))
      if (val) {
       
        this.refresh.costvat = parseFloat(val)
      }
    }
  }
}
