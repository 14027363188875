import { Component, OnInit, Input, EventEmitter, Output, OnDestroy } from '@angular/core';
import { UploadComponent } from './upload/upload.component';
import { isEmpty } from 'lodash';
import { AuthService } from 'app/shared/services/auth.service';
import { SnackbarService } from 'app/shared/services/snackbar.service';
import { Router } from '@angular/router';
import { DatabaseService } from 'app/shared/services/database.service';
import { ThemeService } from 'app/shared/services/theme.service';
import { HelpHeroService } from 'app/shared/services/helphero.service';
import { SubscriptionService } from 'app/shared/services/subscription.service';
import { FormControl } from '@angular/forms';
import { take, takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { DialogService } from 'app/services/dialog.service';

interface Sidenav {
  position: number;
  type: 1 | 2 | 3, // 1: divider; 2; basic; 3: group expand
  name?: string;
  svgIcon?: string; // choose between svgIcon (manual) or icon (material)
  icon?: string;
  url?: string;
  isExpanded?: boolean;
  childrens?: Sidenav[];
  click?: (value: any, event: any) => void;
}

@Component({
  selector: 'app-sidenav',
  templateUrl: './sidenav.component.html',
  styleUrls: ['./sidenav.component.scss']
})
export class SidenavComponent implements OnInit, OnDestroy {

  regions = {
    NA: ['US', 'MX', 'CA'],
    EU: ['UK', 'FR', 'IT', 'DE', 'ES'],
    BR: ['BR'],
    IN: ['IN'],
    CN: ['CN'],
    FE: ['AU', 'JP']
  };
  @Input() set isExpanded(value: boolean) {
    this._isExpanded = value;
    if (value === false) {
      this.mainNavs.forEach(m => {
        if (m.isExpanded !== undefined) {
          m.isExpanded = false;
        }
      });
    }
  };
  @Output() isExpandedChange = new EventEmitter<boolean>();

  _isExpanded = true;

  marketplaces: string[] = [];
  selectedMarketplace: string = 'US';
  selectedCondition: 'new' | 'used' = 'new';
  searchControl = new FormControl('');

  showCondition = false;

  destroy$ = new Subject<boolean>();

  // #region create new nav here
  signOutNav: Sidenav = {
    position: 1,
    type: 2,
    name: 'Sign out',
    icon: 'exit_to_app',
    click: (value: any, event: any) => this.signout(),
  }
  mainNavs: Sidenav[] = [
    {
      position: 1,
      type: 2,
      name: 'Dashboard',
      svgIcon: 'su_dashboard',
      url: '/dashboard',
    },
    {
      position: 2,
      type: 2,
      name: 'Scans',
      svgIcon: 'su_scan',
      url: '/scans',
    },
    {
      position: 3,
      type: 2,
      name: 'Product Folders',
      svgIcon: 'su_folder',
      url: '/product-folders',
    },
    { position: 4, type: 1, },
    {
      position: 5,
      type: 2,
      name: 'Training',
      svgIcon: 'su_training',
      url: '/training',
    },
    {
      position: 6,
      type: 3,
      name: 'Unlimited Plus',
      svgIcon: 'su_unlimited',
      isExpanded: this.router.url.includes('/plus'),
      click: (value, event) => this.toggleDropdown(value, event),
      childrens: [
        {
          position: 1,
          type: 2,
          name: '0 to Freedom',
          url: '/plus/zero-to-freedom',
          click: (value, event) => this._isExpanded ? null : this.toggleMainNavItem(6, event),
        },
        {
          position: 2,
          type: 2,
          name: 'Daily Picks',
          url: '/plus/daily-picks',
          click: (value, event) => this._isExpanded ? null : this.toggleMainNavItem(6, event),
        },
        {
          position: 3,
          type: 2,
          name: 'Reimbursements Plus',
          url: '/plus/promo/si',
          click: (value, event) => this._isExpanded ? null : this.toggleMainNavItem(6, event),
        },
        {
          position: 4,
          type: 2,
          name: 'Inventory Control',
          url: '/plus/promo/sostocked',
          click: (value, event) => this._isExpanded ? null : this.toggleMainNavItem(6, event),
        },
      ]
    },
    {
      position: 7,
      type: 2,
      name: 'Community',
      svgIcon: 'su_community',
      url: '/community-calls',
    },
    { position: 8, type: 1 },
    {
      position: 9,
      type: 2,
      name: 'Settings',
      svgIcon: 'su_setting',
      url: '/settings',
    },
    { position: 10, type: 1 },
  ]

  constructor(
    private authService: AuthService,
    private snackbar: SnackbarService,
    private router: Router,
    private db: DatabaseService,
    public themeService: ThemeService,
    private helpHeroService: HelpHeroService,
    private subscriptionService: SubscriptionService,
    private dialogService: DialogService,
  ) { }

  ngOnInit() {
    try {
      if (this.helpHeroService.helpHero) {
        this.helpHeroService.helpHero.on('tour_started', () => {
          this.isExpandedChange.emit(true);
        });

      }

      // fetch available marketplaces
      this.db.getVerifiedSPMarketplaces().pipe(takeUntil(this.destroy$)).subscribe(
        async (marketplaces: string[]) => {
          const mwsMarketplaces: any = await this.db.getVerifiedMWSMarketplaces().pipe(take(1)).toPromise();
          this.marketplaces = [];
          if (marketplaces.length >= mwsMarketplaces.length) {
            marketplaces.forEach(marketplace => {
              this.marketplaces.push(...this.regions[marketplace]);
            });
          } else {
            if (new Date().getTime() > this.db.deadlineAmazon) {
              marketplaces.forEach(marketplace => {
                this.marketplaces.push(...this.regions[marketplace]);
              });
            } else {
              mwsMarketplaces.forEach(marketplace => {
                this.marketplaces.push(...this.regions[marketplace]);
              });
            }
          }
        }
      );

      this.db.scanSettings.then(data => {
        if (this.marketplaces.length !== 0) {
          this.selectedMarketplace = this.marketplaces[0];
        }
        if (data && data.defaultMarketplace) {
          this.selectedMarketplace = data.defaultMarketplace;
        }
        if (data && data.condition) {
          this.selectedCondition = data.condition;
        }
      });
    } catch (error) {
      console.log(error);
    }

  }

  toggleSidenav() {
    this.isExpandedChange.emit(!this._isExpanded);
  }

  toggleMainNavItem(position: number, event: PointerEvent) {
    // because first position is 1
    this.mainNavs[position - 1].isExpanded = !this.mainNavs[position - 1].isExpanded;
  }

  toggleDropdown(position: number, event: PointerEvent) {
    this.toggleMainNavItem(position, event);

    const element = document.getElementById('childContainer' + position);
    if (element) {
      element.style.top = (event.screenY - 115) + 'px';
      element.style.left = '70px';
    }
  }

  async openModal() {
    // this.config.panelClass = this.themeService.darkTheme ? ['no-padding-dialog-container', 'dark-theme'] : ['no-padding-dialog-container']
    const data = {
      marketplaces: [],
      defaultMarketplace: this.selectedMarketplace,
    };
    const regions = await this.db.verifiedMarketplaces();
    try {
      let emailVerified = this.authService.isEmailVerified();
      if (!emailVerified && !this.subscriptionService.isActive) throw new Error('email');

      if (isEmpty(regions)) {
        this.helpHeroService.helpHero.cancelTour()
        throw new Error('marketplace');
      }
      // this.config.data.marketplaces = []
      regions.forEach(region => {
        data.marketplaces.push(...this.regions[region])
      });

      // this.dialog.open(UploadComponent, this.config);
      this.dialogService.open(UploadComponent, {
        hasBackdrop: true,
        width: '70%',
        maxHeight: '90vh',
        minHeight: '50vh',
        closeOnNavigation: true,
        disableClose: true,
        autoFocus: false,
        data,
      });

    } catch (e) {
      if (e.message === 'marketplace') {
        this.snackbar.showSnackbar('Add a marketplace to start scanning!');
        this.router.navigate(['/settings']);
      }
      if (e.message === 'email') {
        this.snackbar.showSnackbar(
          'Email is not verified! Click activation link in your welcome email!',
          {
            link: '/session/email?mode=verifyEmail&oobCode=none',
            linkMsg: 'Verify Now'
          }
        );
      }
    }
  }

  search() {
    if (this.searchControl.valid) {
      const convertedValue = this.searchControl.value.trim().replace(/[^a-zA-Z0-9 ]/g, '');
      this.showCondition = false;
      this.searchControl.reset();
      this.router.navigate([
        `/product-detail/${this.selectedMarketplace}/${convertedValue}/${this.selectedCondition}`
      ]);
    } else {
      this.snackbar.showSnackbar('Search value must be a UPC, EAN, ISBN, ASIN, or Keyword');
    }
  }

  searchOnClick() {
    if (!this._isExpanded) {
      this.isExpandedChange.emit(true);
    }
  }

  async signout() {
    await this.authService.signOut();
    window.location.href = '/session/signin';
  }

  toggleTheme() {
    this.themeService.setTheme(!this.themeService.darkTheme);
  }

  ngOnDestroy(): void {
    this.destroy$.next(true);
    this.destroy$.unsubscribe();
  }
  // openDiscordModal() {
  //   this.discordConfig.panelClass = this.themeService.darkTheme ? ['no-padding-dialog-container', 'dark-theme', 'no-padding'] : ['no-padding-dialog-container', 'no-padding']
  //   this.discordConfig.data = { dark: this.themeService.darkTheme }
  //   const dialogRef = this.dialog.open(DiscordComponent, this.discordConfig);
  // }
}
