import { NgModule } from '@angular/core';
import { InputDirective, LabelDirective } from './input.directive';



@NgModule({
  declarations: [InputDirective, LabelDirective],
  imports: [
  ],
  exports: [
    InputDirective, LabelDirective
  ]
})
export class InputModule { }
