import {
  Component,
  OnInit,
  Output,
  EventEmitter,
  ViewEncapsulation,
  OnDestroy,
  ViewChild
} from '@angular/core';
import { ActivatedRoute, Router, Event, NavigationEnd } from '@angular/router';
import { UserScanSettings } from '../../../shared/models/user.model';
import { Marketplace } from 'app/shared/models/credentials-api/valid-marketplaces.model';
import { Subject } from 'rxjs';
import { ThemeService } from 'app/shared/services/theme.service';
import { environment } from 'environments/environment';
import { MatMenuTrigger } from '@angular/material';

@Component({
  selector: 'app-toolbar',
  templateUrl: './toolbar.component.html',
  styleUrls: ['./toolbar.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class ToolbarComponent implements OnInit, OnDestroy {
  @ViewChild(MatMenuTrigger, { static: false }) notificationMenu: MatMenuTrigger;

  @Output() debugEmitter: EventEmitter<void> = new EventEmitter<void>();
  // itemDetailSearchForm: FormGroup;
  marketplaces: Array<string> = [];
  conditions: Array<'new' | 'used'> = ['new', 'used']
  userSettings: UserScanSettings;
  selectedMarketplace: string = 'US'
  selectedCondition: 'new' | 'used' = 'new'
  env = environment.environment;

  totalUnread: number = 0;

  regions = {
    NA: ['US', 'MX', 'CA'],
    EU: ['UK', 'FR', 'IT', 'DE', 'ES'],
    BR: ['BR'],
    IN: ['IN'],
    CN: ['CN'],
    FE: ['AU', 'JP']
  };

  toolbarTitle = '';

  destory$ = new Subject<boolean>();

  constructor(
    // private snack: SnackbarService,
    // private db: DatabaseService,
    private router: Router,
    public themeService: ThemeService,
    private route: ActivatedRoute,
  ) {
    this.router.events.subscribe((event: Event) => {
      if (event instanceof NavigationEnd) {
        if (Object.keys(this.route.snapshot.firstChild.data).length) {
          this.toolbarTitle = this.route.snapshot.firstChild.data.toolbar || '';

        } else if (Object.keys(this.route.snapshot.firstChild.firstChild.data).length) {
          this.toolbarTitle = this.route.snapshot.firstChild.firstChild.data.toolbar || '';
          // console.log(this.route.snapshot.firstChild.firstChild)
        }
      }
    });
  }

  async ngOnInit() {
  }

  triggerNetsuite() {
    const el = document.getElementsByClassName('netsuite-contact-form-popup netsuite-popup-btn')[0] as HTMLElement
    el.click()
  }

  selectMarketplace(marketplace: Marketplace) {
    this.selectedMarketplace = marketplace;
  }

  selectCondition(condition: 'new' | 'used') {
    this.selectedCondition = condition;
  }

  switchEnv() {
    if (this.env === 'prod') {
      window.location.href = "https://beta.scanunlimited.com"
    } else {
      window.location.href = "https://app.scanunlimited.com"
    }
  }

  debug() {
    this.debugEmitter.emit()
  }

  totalUnreadChange(value: number) {
    this.totalUnread = value || 0;
  }

  closeNotificationMenu() {
    if (this.notificationMenu) {
      this.notificationMenu.closeMenu();
    }
  }

  ngOnDestroy() {
    this.destory$.next(true);
    this.destory$.unsubscribe();
  }

}
