import { Component, OnInit, Inject, AfterViewInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { FileUploader } from 'ng2-file-upload';
import { FileRead } from 'app/shared/models/upload';
import { UploadService } from 'app/shared/services/upload.service';
import { SnackbarService } from 'app/shared/services/snackbar.service';
import { DatabaseService } from 'app/shared/services/database.service';
import * as _ from 'lodash';
import { Router } from '@angular/router';
import { UploadParseResponse } from 'app/shared/models/uploadParseResponse.model.js';
import { HttpErrorResponse } from '@angular/common/http';
import { dropdownAnimation } from 'app/shared/animations/dropdown-animation.js';
import { Marketplace } from 'app/shared/models/credentials-api/valid-marketplaces.model.js';
import * as Sentry from '@sentry/browser'
import { take } from 'rxjs/internal/operators/take';
import { FirebaseCurrency } from 'app/shared/models/currency.model';
import { CurrencyService } from 'app/shared/services/currency.service';
import { AngularFireAnalytics } from '@angular/fire/analytics';
import { MatTableDataSource } from '@angular/material/table';
import { ThemeService } from 'app/shared/services/theme.service';
import { HelpHeroService } from 'app/shared/services/helphero.service';
import { SubscriptionService } from 'app/shared/services/subscription.service';
import { AnalyticService } from 'app/shared/services/analytic.service';
@Component({
  selector: 'app-upload',
  templateUrl: './upload.component.html',
  styleUrls: ['./upload.component.scss'],
  animations: [dropdownAnimation]
})
export class UploadComponent implements OnInit, AfterViewInit {
  uploadForm: FormGroup;
  loadingSpinner: boolean;
  loadingCheck = false;
  isComplete = false;
  display = 'none';
  idTypes = ['UPC', 'EAN', 'ASIN', 'ISBN', 'KEYWORD'];
  hasHeaders = true;
  currencies: FirebaseCurrency[] = []
  uploader: FileUploader = new FileUploader({
    allowedMimeType: [
      'text/csv',
      'application/vnd.ms-excel',
      'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
      ''
    ],
    queueLimit: 1,
    maxFileSize: 80000000,
    isHTML5: true,
    removeAfterUpload: true
  });
  currentUpload: FileRead;
  uploadParseResponse: UploadParseResponse;
  defaultCurrency: string;
  defaultCondition = 'new';
  defaultMarketplace: Marketplace;
  marketplaces: Marketplace[];
  vatRegistered = false;
  dstRegistered = false;
  pricevat = 0;
  costvat = 0;
  dstFba = 0;
  dstReferral = 0;
  regions = {
    NA: ['US', 'MX', 'CA'],
    EU: ['UK', 'FR', 'IT', 'DE', 'ES'],
    BR: ['BR'],
    IN: ['IN'],
    CN: ['CN'],
    FE: ['AU', 'JP']
  };
  conditions = ['new', 'used'];
  hasBaseDropZoneOver = false;
  startingPosition: number = 0
  dataSource: MatTableDataSource<any>
  columns: any[]
  progress: number = 0;

  constructor(
    public themeService: ThemeService,
    public dialogRef: MatDialogRef<UploadComponent>,
    @Inject(MAT_DIALOG_DATA) public dialogData: any,
    private uploadService: UploadService,
    private snackbar: SnackbarService,
    private db: DatabaseService,
    private router: Router,
    private currencyService: CurrencyService,
    private analytics: AngularFireAnalytics,
    private helpHero: HelpHeroService,
    private subscriptionService: SubscriptionService,
    private analyticService: AnalyticService,
  ) { }

  async ngOnInit() {
    this.uploadForm = new FormGroup({
      commonName: new FormControl(null, [
        Validators.required,
        Validators.minLength(1),
        Validators.maxLength(50)
      ]),
      idType: new FormControl(null, [Validators.required, this.validateKeywordLineCheck]),
      startingPosition: new FormControl(this.startingPosition, Validators.required),
      productIdColumn: new FormControl(null, [Validators.required, this.validateProductIds]),
      costColumn: new FormControl(null, [Validators.required, this.validateCost]),
      currency: new FormControl(null, Validators.required),
      marketplace: new FormControl(null, Validators.required),
      customColumns: new FormControl(null),
      pricevat: new FormControl(this.pricevat),
      costvat: new FormControl(this.costvat),
      dstFba: new FormControl(this.dstFba),
      dstReferral: new FormControl(this.dstReferral),
      condition: new FormControl(null, Validators.required)
    });

    this.currencies = await this.currencyService.getAllCurrencies().toPromise()

    this.uploader.onAfterAddingFile = async () => {
      this.loadingSpinner = true;
      const file: File = this.uploader.queue[this.uploader.queue.length - 1][
        '_file'
      ];
      this.currentUpload = new FileRead(file, file.type, file.name, file.size);
      this.uploadForm
        .get('commonName')
        .setValue(this.currentUpload.name.substr(0, 50));
      try {
        const data = await this.uploadService.parseFile(this.currentUpload);
        const allowedToUpload = this.subscriptionService.canUpload
        if (!allowedToUpload) throw { code: 2 }
        if (this.subscriptionService.isActive && data.lineCount > 100000) {
          throw { code: 1 }
        }
        if (!this.subscriptionService.isActive && data.lineCount > 10000) {
          throw { code: 1 }
        }
        if (data) {
          this.uploadParseResponse = data;
          this.loadingSpinner = false;
          this.uploadForm.get('commonName').markAsTouched();
        } else {
          this.dialogRef.close()
          this.router.navigate(['/session/error']);
        }

        // Add table preview
        this.columns = [];
        let longestRowLength = 0
        // Find longest row length
        for (let row of this.uploadParseResponse.rows) {
          if (row.length > longestRowLength) longestRowLength = row.length
        }

        // Fill smaller rows to make even 2D array
        this.uploadParseResponse.rows = this.uploadParseResponse.rows.map(row => {
          if (row.length < longestRowLength) {
            const diff = longestRowLength - row.length
            for (let i = 0; i < diff; i++) {
              row.push('')
            }
          }
          return row
        })

        // Create columns
        for (let i = 0; i < longestRowLength; i++) {
          this.columns.push(String(i))
        }
        this.dataSource = new MatTableDataSource(this.uploadParseResponse.rows);
      } catch (e) {
        let message: string;
        if (!e) e = {}
        switch (e.code) {
          case 0:
            message = 'Something unexpected happened! If the problem continues, please contact support.';
            break;
          case 1:
            message = 'Files has too many lines! (Free: 10,000 Unlimited: 100,000)';
            break;
          case 2:
            message = 'Free monthly scan exhausted! Please consider upgrading to Unlimited.';
            break;
          case 3:
            message = 'File is empty!';
            break;
          case 4:
            message = 'No file found. Please contact support.';
            break;
          case 5:
            message = 'Please upload a csv or xlsx file';
            break;
          case 6:
            message = 'Something unexpected happened! If the problem continues, please contact support.';
            break;
          case 7:
            message = 'File needs to have at least one sheet'
            break;
          case 8:
            message = 'Check file formatting'
            break;
          default:
            message = 'Something unexpected happened! Please contact support.'
            Sentry.captureException(e)
            break;
        }
        this.snackbar.showSnackbar(message);
        this.loadingSpinner = false;
        this.uploader.clearQueue();
      }
    };

    this.uploader.onWhenAddingFileFailed = (file, filter, options) => {
      if (filter.name === 'queueLimit') {
        this.dialogRef.close();
        this.snackbar.showSnackbar('Please upload one file at a time', {
          link: 'http://help.scanunlimited.com/en/articles/2397227-upload-a-file',
          linkMsg: 'Help Article'
        });
      }
      if (filter.name === 'fileSize') {
        this.snackbar.showSnackbar('File is too large! 80MB max limit!', {
          link: 'http://help.scanunlimited.com/en/articles/2397227-upload-a-file',
          linkMsg: 'Help Article'
        });
      }
      if (filter.name === 'mimeType') {
        this.snackbar.showSnackbar('Please upload a csv or xlsx file', {
          link: 'http://help.scanunlimited.com/en/articles/2397227-upload-a-file',
          linkMsg: 'Help Article'
        });
      }
      this.loadingSpinner = false;
    };

    // set passed in verified marketplaces
    this.marketplaces = this.dialogData.marketplaces

    this.uploadForm.valueChanges.subscribe(data => {
      const productIdColumn = this.uploadForm ? this.uploadForm.get('productIdColumn').value : null
      const costColumn = this.uploadForm ? this.uploadForm.get('costColumn').value : null
      if (costColumn === productIdColumn) {
        if (this.uploadForm.get('costColumn').errors) {
          this.uploadForm.get('costColumn').errors['sameColumn'] = true
        } else {
          this.uploadForm.get('costColumn').setErrors({ 'sameColumn': true })
        }
        if (this.uploadForm.get('productIdColumn').errors) {
          this.uploadForm.get('productIdColumn').errors['sameColumn'] = true
        } else {
          this.uploadForm.get('productIdColumn').setErrors({ 'sameColumn': true })
        }
      } else {
        if (this.uploadForm.get('costColumn').errors) {
          if (Object.keys(this.uploadForm.get('costColumn').errors).length === 1 && this.uploadForm.get('costColumn').errors['sameColumn']) {
            this.uploadForm.get('costColumn').setErrors(null)
          }
        }
        if (this.uploadForm.get('productIdColumn').errors) {
          if (Object.keys(this.uploadForm.get('productIdColumn').errors).length === 1 && this.uploadForm.get('productIdColumn').errors['sameColumn']) {
            this.uploadForm.get('productIdColumn').setErrors(null)
          }
        }
      }
    })
  }

  ngAfterViewInit() {
    // fetch users default currency and marketplace
    this.db.scanSettings.then(data => {
      this.defaultCurrency = data.currency;
      if (this.defaultCurrency) {
        this.uploadForm.get('currency').setValue(this.defaultCurrency)
      }
      if (data.condition) {
        this.defaultCondition = data.condition;
        this.uploadForm.get('condition').setValue(this.defaultCondition)
      }
      if (this.marketplaces.includes(data.defaultMarketplace)) {
        this.defaultMarketplace = data.defaultMarketplace;
        this.uploadForm.get('marketplace').setValue(this.defaultMarketplace)
      }
    });
  }

  fileRemoved() {
    this.uploadForm.reset({
      currency: this.defaultCurrency,
      marketplace: this.defaultMarketplace
    });
  }

  fileOverBase(e: any): void {
    this.hasBaseDropZoneOver = e;
  }

  validateKeywordLineCheck = (control: FormControl) => {
    if (control.value === null) return null
    const linecount = this.uploadParseResponse.lineCount
    if (linecount > 10000 && control.value === 'KEYWORD') {
      return { 'invalid': true }
    } else {
      return null
    }
  }

  validateCost = (control: FormControl) => {
    if (control.value !== null) {

      const check = this.uploadParseResponse.data.slice(0, 100).some(row => {
        try {
          const value = Number(String(row[control.value]).replace(/[^A-Z0-9.]/gi, ''))
          if (value && value < 10000) {
            return true
          } else {
            return false
          }
        } catch (error) {
          return false
        }
      })

      if (check) {
        return null
      } else {
        return { 'invalid': true }
      }
    }


  }

  validateProductIds = (control: FormControl) => {

    const idType = this.uploadForm ? this.uploadForm.get('idType').value : null
    if (!idType) return null


    if (idType === 'UPC' || idType === 'EAN' || idType === 'ISBN' && control.value !== null) {
      const check = this.uploadParseResponse.data.slice(0, 100).some(row => {
        let stringId = String(row[control.value]).trim()
        if (stringId.length < 10 || stringId.length > 20) return false

        // if (idType === 'UPC' && stringId.length !== 12) {
        //   return false;
        // }
        // if (idType === 'EAN' && stringId.length !== 13) {
        //   return false;
        // }
        // if (idType === 'ISBN' && stringId.length !== 13) {
        //   return false;
        // }

        stringId = stringId.replace(/[^0-9]/gi, '')

        if (stringId.length < 10) return false

        const stringNoDigit = stringId.substr(0, stringId.length - 1);

        const oddMultiplier = (stringNoDigit.length % 2) ? 3 : 1;
        const evenMultiplier = (oddMultiplier === 1) ? 3 : 1;

        const sum = stringNoDigit.split('')
          .map((val, index) => {
            const valNum = parseInt(val, 10);

            if (index % 2) {
              return valNum * evenMultiplier;
            }

            return valNum * oddMultiplier
          })
          .reduce((acc, val) => acc + val, 0);

        return (stringNoDigit + (10 - (sum % 10)) % 10) === stringId
      })

      if (check) {
        return null
      } else {
        return { 'invalid': true }
      }

    } else if (idType === 'ASIN' && control.value !== null) {
      const check = this.uploadParseResponse.data.slice(0, 100).some(row => {
        let stringId = String(row[control.value]).trim()
        stringId = stringId.replace(/[^a-zA-Z0-9]/g, '');
        return (stringId.length === 10 && (stringId.startsWith('B') || stringId.match(/^[0-9]/))) ? true : false
      })

      if (check) {
        return null
      } else {
        return { 'invalid': true }
      }
    }

  }

  selectRow(rowNumber: number) {
    this.startingPosition = rowNumber
    this.uploadForm.get('startingPosition').setValue(rowNumber)
  }

  async submitUpload() {
    this.uploadForm.markAllAsTouched()

    if (this.uploadForm.valid) {
      this.loadingCheck = true;
      const formValues = this.uploadForm.value;

      if (this.regions.EU.indexOf(formValues.marketplace) === -1) {
        formValues.pricevat = formValues.costvat = null;
      }
      formValues.commonName = formValues.commonName.trim();

      // get custom column names
      if (formValues.customColumns && formValues.customColumns.length > 0) {
        const customColumnNamesArray: string[] = formValues.customColumns.map(index => {
          const customCol = String(this.uploadParseResponse.rows[formValues.startingPosition][index])
          return customCol.replace('\n', '').trim()
        })
        formValues.customColumnNames = customColumnNamesArray.join(',')
      }
      // adjust line count
      this.uploadParseResponse.lineCount = this.uploadParseResponse.lineCount - formValues.startingPosition

      // remove rows prior to starting position
      this.uploadParseResponse.data = this.uploadParseResponse.data.slice(formValues.startingPosition, this.uploadParseResponse.data.length)

      try {
        const { storageRef, uploadedRef, blob, scanRef, finalUpload } = await this.uploadService.submitUpload(
          this.uploadParseResponse,
          formValues,
          this.currentUpload.name
        );
        const uploadtask = storageRef.put(blob)
        uploadtask.on('state_changed',
          (snapshot) => {
            this.progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
          },
          (error) => {
            Sentry.captureException(error)
          })
        await uploadtask

        await scanRef.update(finalUpload)
        await uploadedRef.update({ uploaded: true })

        setTimeout(() => {
          this.isComplete = true;
          this.display = 'block';
        }, 3000);
        setTimeout(() => {
          this.fileRemoved();
          this.dialogRef.close(true);
        }, 4000);
        this.analyticService.submitEvent('uploaded-file', {
          name: this.currentUpload.name
        });
        // (<any>window).Intercom('trackEvent', 'uploaded file', {
        //   name: this.currentUpload.name
        // });
        await this.analytics.logEvent('uploaded_file')
        this.helpHero.close()
      } catch (e) {
        const error: HttpErrorResponse = e;
        let message = 'There was an unexpected error. Please contact support'

        this.loadingCheck = false;
        this.fileRemoved();
        this.dialogRef.close();

        if (error.status === 1) {
          message = 'Detected scientific notation in the Product Id column! Edit and reupload'
          return this.snackbar.showSnackbar(message, {
            link: 'http://help.scanunlimited.com/en/articles/4340339-how-to-fix-scientific-notation-error',
            linkMsg: 'Help Article'
          }, 10000);
        }
        this.snackbar.showSnackbar(message);
      }
    } else {
      if (this.uploadForm.get('commonName').hasError('maxlength')) {
        this.snackbar.showSnackbar('Scan name must not exceed 30 characters', {
          link: 'http://help.scanunlimited.com/en/articles/2397227-upload-a-file',
          linkMsg: 'Help Article'
        })
        return
      }
      this.snackbar.showSnackbar('All required fields must be filled in', {
        link: 'http://help.scanunlimited.com/en/articles/2397227-upload-a-file',
        linkMsg: 'Help Article'
      })
    }
  }

  toggleVAT(checked: boolean) {
    this.vatRegistered = checked;
    if (checked) {
      this.uploadForm.get('pricevat').setValue(20)
      this.uploadForm.get('costvat').setValue(20)
      this.pricevat = 20
      this.costvat = 20
    } else {
      this.uploadForm.get('pricevat').setValue(0)
      this.uploadForm.get('costvat').setValue(0)
      this.pricevat = 0
      this.costvat = 0
    }
  }
  toggleDST(checked: boolean) {
    this.dstRegistered = checked;
    if (checked) {
      this.uploadForm.get('dstFba').setValue(2)
      this.uploadForm.get('dstReferral').setValue(2)
      this.dstFba = 2
      this.dstReferral = 2
    } else {
      this.uploadForm.get('dstFba').setValue(0)
      this.uploadForm.get('dstReferral').setValue(0)
      this.dstFba = 0
      this.dstReferral = 0
    }
  }

  downloadTemplate() {
    window.open('assets/data/upload-template.csv')
  }

  promptPerc(valType: string) {

    let val = ''
    if (valType === 'price') {
      val = prompt('Price VAT', this.pricevat.toFixed(2))
      if (val) {
        this.uploadForm.get('pricevat').setValue(parseFloat(val))
        this.pricevat = parseFloat(val)
      }
    } 
    else if (valType === 'dstFba') {
      val = prompt('DST FBA Fee', this.dstFba.toFixed(2))
      if (val) {
        this.uploadForm.get('dstFba').setValue(parseFloat(val))
        this.dstFba = parseFloat(val)
      }
    }
    else if (valType === 'dstReferral') {
      val = prompt('DST Referral Fee', this.dstFba.toFixed(2))
      if (val) {
        this.uploadForm.get('dstReferral').setValue(parseFloat(val))
        this.dstFba = parseFloat(val)
      }
    }else {
      val = prompt('Cost VAT', this.costvat.toFixed(2))
      if (val) {
        this.uploadForm.get('costvat').setValue(parseFloat(val))
        this.costvat = parseFloat(val)
      }
    }
  }

}
