import { Directive } from '@angular/core';

@Directive({
  selector: 'input [suInput], select [suInput], textarea [suInput]',
  host: {
    'class': 'su-input'
  }
})
export class InputDirective {

  constructor() { }

}

@Directive({
  selector: 'label [suLabel]',
  host: {
    'class': 'su-label'
  }
})
export class LabelDirective {

  constructor() { }

}
