import { ComponentType } from '@angular/cdk/portal';
import { Injectable, TemplateRef } from '@angular/core';
import { MatDialog, MatDialogConfig } from '@angular/material';
import { ThemeService } from 'app/shared/services/theme.service';

@Injectable({
  providedIn: 'root'
})
export class DialogService {

  defaultConfig: MatDialogConfig = {
  }

  constructor(
    private matDialog: MatDialog,
    private themeService: ThemeService,
  ) { }

  open<T, D>(omponentOrTemplateRef: ComponentType<T> | TemplateRef<T>, config?: MatDialogConfig<D>) {
    try {
      return this.matDialog.open(omponentOrTemplateRef, {
        ...this.defaultConfig,
        panelClass: [(this.themeService.darkTheme ? 'dark-theme' : ''), 'su-dialog'],
        ...config,
      });
    } catch (error) {
      throw error;
    }
  }
}
