import { NgModule } from '@angular/core';
import { Routes, RouterModule, PreloadAllModules } from '@angular/router';
import { AuthComponent } from './layouts/auth/auth.component';
import { AdminComponent } from './layouts/admin/admin.component';

const routes: Routes = [
  {
    path: '',
    redirectTo: 'dashboard',
    pathMatch: 'full',
  },
  {
    path: '',
    component: AdminComponent,
    children: [
      {
        path: 'dashboard',
        loadChildren: () => import('./dashboard/dashboard.module').then(m => m.DashboardModule),
        data: { toolbar: 'Dashboard' }
      },
      {
        path: 'scans',
        loadChildren: () => import('./scans/scans.module').then(m => m.ScansModule),
        data: { toolbar: 'Scans' }
      },
      {
        path: 'product-folders',
        loadChildren: () => import('./product-folders/product-folders.module').then(m => m.ProductFoldersModule),
        data: { toolbar: 'Product Folders' }
      },
      {
        path: 'settings',
        loadChildren: () => import('./settings/settings.module').then(m => m.SettingsModule),
        data: { toolbar: 'Settings' }
      },
      {
        path: 'subscription',
        loadChildren: () => import('./subscription/subscription.module').then(m => m.SubscriptionModule)
      },
      {
        path: 'product-detail',
        loadChildren: () => import('./product-detail/product-detail.module').then(m => m.ItemDetailModule),
        data: { toolbar: 'Product Detail' }
      },
      {
        path: 'community-calls',
        loadChildren: () => import('./community-calls/community-calls.module').then(m => m.CommunityCallsModule),
        data: { toolbar: 'Community Calls' }
      },
      {
        path: 'success-talks',
        loadChildren: () => import('./success-talks/success-talks.module').then(m => m.SuccessTalksModule),
        data: { toolbar: 'Success Talks' }
      },
      {
        path: 'plus',
        children: [
          {
            path: 'zero-to-freedom',
            loadChildren: () => import('./plus/zero-to-freedom/zero-to-freedom.module').then(m => m.ZeroToFreedomModule),
            data: { toolbar: 'Zero to Freedom' }
          },
          {
            path: 'daily-picks',
            loadChildren: () => import('./plus/daily-picks/daily-picks.module').then(m => m.DailyPicksModule),
            data: { toolbar: 'Daily Picks' }
          },
          {
            path: 'promo/:id',
            loadChildren: () => import('./plus/promo/promo.module').then(m => m.PromoModule),
            data: { toolbar: 'Promo' }
          }

        ]
      },
      {
        path: 'training',
        loadChildren: () => import('./training/training.module').then(m => m.TrainingModule),
        data: { toolbar: 'Training' }
      }
    ]
  },
  {
    path: '',
    component: AuthComponent,
    children: [
      {
        path: 'session',
        loadChildren: () => import('./session/session.module').then(m => m.SessionModule)
      }
    ]
  },
  {
    path: '**',
    redirectTo: 'session/404'
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules })],
  exports: [RouterModule]
})
export class AppRoutingModule { }
