import { Injectable } from '@angular/core'
import { FileRead } from '../models/upload'
import { UploadParseResponse } from '../models/uploadParseResponse.model'
import { AuthService } from './auth.service';
import firebase from 'firebase/app';
import 'firebase/storage';
import { HttpErrorResponse } from '@angular/common/http';
import { environment } from 'environments/environment';
@Injectable({
  providedIn: 'root'
})
export class UploadService {
  constructor(private authService: AuthService) {}

  parseInWorker(file: File, type: string): Promise<UploadParseResponse> {
    return new Promise((resolve, reject) => {
      if (typeof Worker !== 'undefined') {
        const worker = new Worker('../../app.worker', { type: 'module' })
        worker.onmessage = async ({ data }) => {
          if (data === 'error') return reject({ code: 0 })
          if (data.code) return reject(data)
          const results: UploadParseResponse = data
          if (results.lineCount === 0) return reject({ code: 3 })
          resolve(results)
          worker.terminate()
        }
        worker.postMessage({
          file,
          type
        })
      } else {
        reject('web worker not supported in this environment')
      }
    })
  }

  parseFile(currentUpload: FileRead): Promise<UploadParseResponse> {
    if (currentUpload.contentType === undefined) {
      return Promise.reject({ code: 4 })
    }

    // check for incorrect file type and clear queue
    if (
      currentUpload.contentType !== 'text/csv' &&
      !currentUpload.contentType.startsWith('application/vnd.ms-excel') &&
      currentUpload.contentType !== '' &&
      !currentUpload.contentType.startsWith(
        'application/vnd.openxmlformats-officedocument'
      )
    ) {
      return Promise.reject({ code: 5 })
    }

    if (
      currentUpload.contentType === 'text/csv' ||
      currentUpload.file.name
        .substr(currentUpload.name.length - 4, 4)
        .toLowerCase() === '.csv'
    ) {
      return this.parseInWorker(currentUpload.file, 'csv')
    } else if (
      currentUpload.contentType.startsWith(
        'application/vnd.openxmlformats-officedocument'
      ) ||
      currentUpload.contentType.startsWith('application/vnd.ms-excel') ||
      currentUpload.file.name
        .substr(currentUpload.name.length - 4, 4)
        .toLowerCase() === '.xls' ||
      currentUpload.file.name
        .substr(currentUpload.name.length - 5, 5)
        .toLowerCase() === '.xlsx'
    ) {
      return this.parseInWorker(currentUpload.file, 'xlsx')
    } else {
      return Promise.reject({ code: 5 })
    }
  }

  async submitUpload(
    data: UploadParseResponse,
    formValues: any,
    name: string
  ) {
    const finalUpload = {};
    const database = firebase.database();

    const canceled = false;
    const commonName = formValues.commonName.trim()
      ? formValues.commonName
      : name;
    let costColumn = formValues.costColumn;
    const currency = formValues.currency;
    const customColumnNames = formValues.customColumnNames || ''
    let customColumnsArray: number[] = formValues.customColumns
    const hasHeaders = false;
    const idType = formValues.idType;
    const lineCount = hasHeaders ? data.lineCount - 1 : data.lineCount;
    let productIdColumn = formValues.productIdColumn;
    for (let i = 0; i < data.data.length; i++) {
      let row = data.data[i]
      if(Number(String(row[costColumn]).replace(/[^0-9.]/gi, '')) > 10000){
        row[costColumn] = 0;
      }
      if(idType !== 'KEYWORD' && idType !== 'ASIN'){
        if (/E\+/gi.test(row[productIdColumn])) {
          return Promise.reject(new HttpErrorResponse({status: 1}))
        }
      }
    }
    const progress = 0;
    const uploaded = false;
    const marketplace = formValues.marketplace;
    const startingPosition = data.rows[formValues.startingPosition].length;
    const fulfillmentMethod = 'FBA'
    const uid = this.authService.currentUserId;
    const scanRef = database.ref(`scans/${uid}/`);
    const key = scanRef.push().key;
    const fileName = key;
    const pricevat = formValues.pricevat;
    const costvat = formValues.costvat;
    const dstFba = formValues.dstFba;
    const dstReferral = formValues.dstReferral;
    const condition = formValues.condition;

    // Remove unused columns
    data.data = data.data.map(row => {
      const newRow = []
      newRow.push(row[formValues.productIdColumn])
      newRow.push(row[formValues.costColumn])
      if(Array.isArray(customColumnsArray)){
        customColumnsArray.forEach(position => {
          newRow.push(row[position])
        })
      }
      return newRow
    })
    productIdColumn = 0
    costColumn = 1
    if(Array.isArray(customColumnsArray)){
      customColumnsArray = customColumnsArray.map((position, i) => i + 2)
    }

    const customColumns: string = Array.isArray(customColumnsArray) ? customColumnsArray.join(',') : ''
      const dataSource = null;
     
      let scanmeta = {
        canceled,
        commonName,
        costColumn,
        currency,
        customColumns,
        hasHeaders,
        idType,
        lineCount,
        productIdColumn,
        progress,
        uploaded,
        marketplace,
        startingPosition,
        fileName,
        pricevat: pricevat ? pricevat / 100 : 0,
        costvat: costvat ? costvat / 100 : 0,
        dstFba: dstFba ? dstFba / 100 : 0,
        dstReferral: dstReferral ? dstReferral / 100 : 0,
        condition,
        customColumnNames,
        fulfillmentMethod,
        dataSource
      };

    finalUpload[key] = scanmeta

    const json = JSON.stringify(data.data)
    const blob = new Blob([json], {type: "application/json"});
    const storageRef = firebase.storage().ref(`${this.authService.currentUserId}/${key}.json`)
    const uploadedRef = database.ref(`scans/${uid}/${key}`)
    return {storageRef, uploadedRef, blob, scanRef, finalUpload}
  }
}
