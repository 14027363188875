import { Injectable } from '@angular/core';
import { SnackbarService } from './snackbar.service';

@Injectable({
  providedIn: 'root'
})
export class ThemeService {
  public darkTheme: boolean

  constructor(
    private snackbar: SnackbarService,
  ) {
    try {
      this.darkTheme = localStorage.getItem('darkTheme') === 'true' ? true : false;
      this.toggleClassInBody(this.darkTheme);

    } catch (error) {
      this.darkTheme = false
    }
  }

  setTheme(value: boolean){
    try {
      this.darkTheme = value;
      localStorage.setItem('darkTheme', String(this.darkTheme));
      this.toggleClassInBody(this.darkTheme);
    } catch (error) {
      this.snackbar.showSnackbar('Failed to save theme preference')
    }
  }

  toggleClassInBody(isDark: boolean) {
    const bodyRef = document.getElementsByTagName('body') ? document.getElementsByTagName('body')[0] : null;
    if (bodyRef === null) return;

    if (isDark) {
      bodyRef.classList.add('dark-theme');

    } else {
      bodyRef.classList.remove('dark-theme');

    }

  }
}
