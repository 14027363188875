import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'app-choose-condition-marketplace',
  templateUrl: './choose-condition-marketplace.component.html',
  styleUrls: ['./choose-condition-marketplace.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ChooseConditionMarketplaceComponent implements OnInit {
  @Input() selectedCondition: string = 'new';
  @Input() selectedMarketplace: string = 'US';
  @Output() selectedConditionChange = new EventEmitter();
  @Output() selectedMarketplaceChange = new EventEmitter();

  @Input() show: boolean = false;
  @Output() showChange = new EventEmitter();

  @Input() conditions: string[] = [];
  @Input() set marketplaces(values: string[]) {
    this._marketplaces = values.map(v => ({
      code: v,
      title: this.convertMarketplaceCode(v)
    }))
  }

  _marketplaces: { code: string; title: string }[] = [];
  constructor() { }

  ngOnInit() {
  }

  selectCondition(value: string) {
    this.selectedConditionChange.emit(value);
    // this.showChange.emit(false);
  }

  selectMarketplace(value: string) {
    this.selectedMarketplaceChange.emit(value);
    // this.showChange.emit(false);
  }

  convertMarketplaceCode(code: string) {
    switch (code) {
      case 'UK': return 'United Kingdom';
      case 'FR': return 'France';
      case 'IT': return 'Italy';
      case 'DE': return 'Germany';
      case 'ES': return 'Spain';
      case 'US': return 'United States';
      case 'MX': return 'Mexico';
      case 'CA': return 'Canada';
      default: '-'
    }
  }
}
