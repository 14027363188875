import { Component, EventEmitter, OnDestroy, OnInit, Output } from '@angular/core';
import { UserNotification } from 'app/shared/models/userNotification.model';
import { Router } from '@angular/router';
import { DatabaseService } from 'app/shared/services/database.service';
import { takeUntil } from 'rxjs/operators';
import { orderBy } from 'lodash';
import { Subject } from 'rxjs';
import { ThemeService } from 'app/shared/services/theme.service';

@Component({
  selector: 'app-notifications',
  templateUrl: './notifications.component.html',
  styleUrls: ['./notifications.component.scss']
})
export class NotificationsComponent implements OnInit, OnDestroy {
  totalUnread = 0;
  notifications: UserNotification[];

  @Output() totalUnreadChange = new EventEmitter<number>();
  @Output() menuChange = new EventEmitter<boolean>();

  destory$ = new Subject<boolean>();

  constructor(
    private router: Router,
    private db: DatabaseService,
    public themeService: ThemeService,
  ) { }

  ngOnInit(): void {
    this.db.notificationsList.pipe(takeUntil(this.destory$)).subscribe(
      notifications => {
        this.notifications = orderBy(notifications, ['date'], ['desc']);
        this.totalUnread = 0;
        for (const notification of this.notifications) {
          if (notification.read !== undefined && !notification.read) {
            this.totalUnread++;
          }
        }
        this.totalUnreadChange.emit(this.totalUnread);
      }
    );
  }

  async notificationClick(notification: UserNotification) {
    if (!notification.read) {
      this.db.readNotification(notification.key);
    }
    if (!this.router.url.includes(notification.link)) {
      await this.router.navigateByUrl('/-', { skipLocationChange: true });
      this.router.navigate([notification.link]);

    }
    this.menuChange.emit(true);
  }

  markAllNotifAsRead() {
    for (const notification of this.notifications) {
      if (notification.read === false || notification.read === undefined) {
        this.db.readNotification(notification.key);
      }
    }
  }

  ngOnDestroy(): void {
    this.destory$.next(true);
    this.destory$.unsubscribe();
  }
}
