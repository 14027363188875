import { Component, OnInit, Input, ChangeDetectorRef, OnChanges } from '@angular/core';
import { round } from 'lodash';
import { UserScanSettings } from 'app/shared/models/user.model';
import { DatabaseService } from 'app/shared/services/database.service';
import { Subject } from 'rxjs';
import { debounceTime } from 'rxjs/operators';
import { PackageDimensions, NewFees } from 'app/shared/models/sis/product-detail.model';
import { CurrencyService } from 'app/shared/services/currency.service';
import { SisService } from 'app/shared/services/sis.service';
import { Marketplace } from 'app/shared/models/credentials-api/valid-marketplaces.model';
import { SnackbarService } from 'app/shared/services/snackbar.service';
import * as Sentry from '@sentry/browser'
import { ScanMeta } from 'app/shared/models/scan-meta/scanMeta.model';


@Component({
  selector: 'app-calculator',
  templateUrl: './calculator.component.html',
  styleUrls: ['./calculator.component.scss']
})
export class CalculatorComponent implements OnInit {
  @Input() marketplace: Marketplace;
  @Input() productCost = 0;
  @Input() requestedCurrency: string;
  @Input() vat = 0;
  @Input() closingFee = 0;
  @Input() packageDims: PackageDimensions;
  @Input() asin: string;
  @Input() fulfillmentMethod: 'FBA' | 'MFN' = 'FBA';
  @Input() scanDetailDetectionRef: ChangeDetectorRef
  @Input() costvat: number = 0;
  @Input() pricevat: number = 0;
  @Input() dstFba: number = 0;
  @Input() dstReferral: number = 0;
  @Input() productPrice = 0;
  @Input() scanId: string;
  @Input() conversionRate:number = 1;

  fees: NewFees;
  fulfillmentMethods = ['FBA', 'MFN'];
  _productPrice: number
  shippingCost = 0;
  @Input() prepFee = 0;
  @Input() percentAdditional = 0;
  percentAdditionalCost = 0;
  sellerProceeds: number;
  netvatValue: number = 0;
  dstValue: number = 0;
  currencySymbol: string;
  netProfit: number;
  exchangeMultiple: number;
  priceCalculating: boolean;
  shippingCalculating: boolean;
  prepFeeCalculating: boolean;
  percentAdditionalCostCalculating: boolean;
  costCalculating: boolean;
  debounceEvents: Subject<{}> = new Subject();
  productPriceError: boolean;
  shippingCostError: boolean;
  productCostError: boolean;
  closingFeeError: boolean;
  prepFeeError: boolean;
  percentAdditionalCostError: boolean;
  loading = true;
  scanMeta: ScanMeta;

  userSettings:
    | UserScanSettings
    | { costProfile: number; currency: string } = {
      costProfile: 0,
      currency: 'USD'
    };

  constructor(
    private sisService: SisService,
    private snackbarService: SnackbarService,
    private db: DatabaseService,
    private currencyService: CurrencyService) { }

  async ngOnInit() {
    //console.log('SCANID', this.scanId)
    if (this.costvat) {
      this.costvat = this.costvat * 100;
    }
    if (this.pricevat) {
      this.pricevat = this.pricevat * 100;
    }
    if (this.dstFba) {
      this.dstFba = this.dstFba * 100;
    }
    if (this.dstReferral) {
      this.dstReferral = this.dstReferral * 100;
    }

   

    //console.log("PRICEE", this.productPrice)
    this.db.scanSettings.then(async settings => {
      if (settings.costProfile) {
        this.userSettings.costProfile = settings.costProfile;
        this.shippingCost =
          this.userSettings.costProfile *
          this.packageDims.weight
      } else {
        this.userSettings.costProfile = 0;
      }

      if (settings.currency) {
        this.userSettings.currency = settings.currency;
      }

      try {
        if (!this.requestedCurrency) {
          this.requestedCurrency = this.userSettings.currency;
        }
        this.exchangeMultiple = await this.currencyService.exchange(this.requestedCurrency, {
          marketplace: this.marketplace
        });
        const shippingMultiple = await this.currencyService.exchange(
          this.requestedCurrency,
          {
            currency: this.userSettings.currency
          }
        );
        this.shippingCost =
          Math.round(this.shippingCost * shippingMultiple * 100) / 100;
      } catch (e) {
        this.exchangeMultiple = 1;
      }
      switch (this.requestedCurrency) {
        case 'USD':
          this.currencySymbol = '$';
          break;
        case 'CAD':
          this.currencySymbol = 'CA$';
          break;
        case 'MXN':
          this.currencySymbol = 'MX$';
          break;
        case 'GBP':
          this.currencySymbol = '£';
          break;
        case 'EUR':
          this.currencySymbol = '€';
          break;
        default:
          this.currencySymbol = '$';
          break;
      }

      if (this.productPrice) {
        const value = round(this.productPrice, 2);
        this.recalc({ id: 'productPrice', value });
      }
    });

    this.debounceEvents.pipe(debounceTime(1000)).subscribe((event: HTMLInputElement) => {
      this.productPriceError = false;
      this.productCostError = false;
      this.shippingCostError = false;
      this.percentAdditionalCostError = false;
      this.prepFeeError = false;
      this.recalc(event);
    });
  }

  // @Input() set productPrice(value: number){
  //   console.log(value)
  //   value = round(value, 2)
  //   const test = round(value * this.exchangeMultiple, 2)

  //   // following if statement should only trigger once when buy box price is returned from product detail request
  //   if(value && (this._productPrice !== test)){
  //       this.recalc({id: 'productPrice', value: test});
  //   }
  // }

  async recalc(element?: HTMLInputElement | any) {
    const backup = {
      _productPrice: this._productPrice,
      productCost: this.productCost,
      vat: this.vat,
      fulfillmentMethod: this.fulfillmentMethod,
      fees: this.fees,
      shippingCost: this.shippingCost,
      sellerProceeds: this.sellerProceeds,
      netProfit: this.netProfit,
      closingFee: this.closingFee
    }

    try {

      if (element) {
        if (element.id === 'productPrice') {
          const val = Number(element.value)
          if (Number.isNaN(val) || val === 0) {
            return this.productPriceError = true;
          } else {
            this._productPrice = parseFloat(val.toFixed(2));
          }
        }
        if (element.id === 'shippingCost') {
          const val = Number(element.value)
          if (Number.isNaN(val)) {
            return this.shippingCostError = true;
          } else {
            this.shippingCost = val;
          }
        }
        if (element.id === 'prepFee') {
          const val = Number(element.value)
          if (Number.isNaN(val)) {
            return this.prepFeeError = true;
          } else {
            this.prepFee = val;
          }
        }
        if (element.id === 'closingFee') {
          const val = Number(element.value);
          if (Number.isNaN(val)) {
            return this.closingFeeError = true;
          } else {
            this.closingFee = val;
          }
        }
        if (element.id === 'productCost') {
          const val = Number(element.value)
          if (Number.isNaN(val)) {
            return this.productCostError = true;
          } else {
            this.productCost = val;
          }
        }
        if (element.id === 'percentAdditionalCost') {
          const val = Number(element.value)
          if (Number.isNaN(val)) {
            return this.percentAdditionalCostError = true;
          } else {
            this.percentAdditional = val
          }
        }
        if (element.id === 'costvat') {
          const val = Number(element.value);
          if (Number.isNaN(val)) {

          } else {
            this.costvat = val;
          }
        }
        if (element.id === 'pricevat') {
          const val = Number(element.value);
          if (Number.isNaN(val)) {

          } else {
            this.pricevat = val;
          }
        }

        if (element.id === 'dstFba') {
          const val = Number(element.value);
          if (Number.isNaN(val)) {

          } else {
            this.dstFba = val;
          }
        }

        if (element.id === 'dstReferral') {
          const val = Number(element.value);
          if (Number.isNaN(val)) {

          } else {
            this.dstReferral = val;
          }
        }

      }

      this.percentAdditionalCost = this.percentAdditional / 100 * this.productCost;

      // if(element && element.source){
      //   this.vat = element.value / 100;
      // }

      // let vatValue = 0;
      // if (this.vat) {
      //   vatValue = round(
      //     this._productPrice - this._productPrice / (1 + Number(this.vat)),
      //     2
      //   );
      // }

      if (element && element.id === 'productPrice' || element && element.id === 'dstFba' || element && element.id === 'dstReferral') {
        var dstFba = this.dstFba
        var dstReferral = this.dstReferral
        if(this.dstFba >1){
          dstFba = this.dstFba/100
        }
        if(this.dstReferral >1){
          dstReferral = this.dstReferral/100
        }
        const feesResults = await this.sisService.getFees(this.asin, this.marketplace, round(this._productPrice / this.exchangeMultiple, 2)).toPromise()
        this.fees = feesResults[0]
        var dstFbaAmount =  this.fees.fbaFee * dstFba
        var dstReferralAmount =  this.fees.referralFee * dstReferral
        this.fees.fbaFee = this.fees.fbaFee * this.exchangeMultiple;
        this.fees.referralFee = this.fees.referralFee * this.exchangeMultiple;
        this.fees.variableClosingFee = this.fees.variableClosingFee * this.exchangeMultiple;
        this.dstValue = (dstFbaAmount + dstReferralAmount) * this.exchangeMultiple;
      }

      // calculate net VAT
      if (this.marketplace !== 'US' && this.marketplace !== 'CA' && this.marketplace !== 'MX') {
        const pricevatValue = this._productPrice - this._productPrice / (1 + Number(this.pricevat / 100));
        const costvatValue = this.productCost - this.productCost / (1 + Number(this.costvat / 100));
        this.netvatValue = pricevatValue - costvatValue;
      } else {
        this.netvatValue = 0;
      }

      this.sellerProceeds =
        this._productPrice -
        (this.fulfillmentMethod === 'FBA' ? (this.fees.fbaFee <= 0 ? 0 : this.fees.fbaFee) : 0) -
        (this.fees.referralFee <= 0 ? 0 : this.fees.referralFee) -
        (this.fees.variableClosingFee <= 0 ? 0 : this.fees.variableClosingFee) -
        this.netvatValue -
        this.shippingCost -
        this.prepFee



      this.netProfit = this.sellerProceeds - this.productCost - this.percentAdditionalCost -  this.dstValue 

      // case when fbafee return -1;
      if (this.fees && this.fees.fbaFee === -1) {
        this.fulfillmentMethod = 'MFN';
      }

    } catch (error) {
      this._productPrice = backup._productPrice
      this.productCost = backup.productCost
      this.vat = backup.vat
      this.fulfillmentMethod = backup.fulfillmentMethod
      this.fees = backup.fees
      this.shippingCost = backup.shippingCost
      this.sellerProceeds = backup.sellerProceeds
      this.netProfit = backup.netProfit
      this.snackbarService.showSnackbar('Error: Calculation Failed!')
      Sentry.captureException(error)
    }
    this.priceCalculating = false;
    this.costCalculating = false;
    this.shippingCalculating = false;
    this.percentAdditionalCostCalculating = false;
    this.prepFeeCalculating = false
    this.loading = false
    // this.productCost *= this.conversionRate
    // this.productCost = parseFloat(this.productCost.toFixed(2))

    if (this.scanDetailDetectionRef) this.scanDetailDetectionRef.detectChanges()
  }

  promptPerc(valType: string) {

    let val = ''
    if (valType === 'price') {
      val = prompt('Price VAT', this.pricevat.toFixed(2))
      if (val) {

        this.pricevat = parseFloat(val)
        this.recalc({ id: 'pricevar', value: this.pricevat })
      }
    } 
    else if (valType === 'dstFba') {
      val = prompt('DST FBA', this.dstFba.toFixed(2))
      if (val) {

        this.dstFba = parseFloat(val)
        this.recalc({ id: 'dstFba', value: this.dstFba })
      }
    }
    else if (valType === 'dstReferral') {
      val = prompt('DST Referral', this.dstReferral.toFixed(2))
      if (val) {

        this.dstReferral = parseFloat(val)
        this.recalc({ id: 'dstReferral', value: this.dstReferral })
      }
    }
    else {
      val = prompt('Cost VAT', this.costvat.toFixed(2))
      if (val) {

        this.costvat = parseFloat(val)
        this.recalc({ id: 'costvat', value: this.costvat })
      }
    }
  }
}
