import { Component } from '@angular/core';
import { ThemeService } from './shared/services/theme.service';
import { MatIconRegistry } from '@angular/material';
import { DomSanitizer } from '@angular/platform-browser';

@Component({
  selector: 'app-root',
  template: `<div id="main-app-element" [ngClass]="{'dark-theme': themeService.darkTheme}">
  <router-outlet></router-outlet>
  </div>`,
  styles: ['#main-app-element {height: 100%;}']
})
export class AppComponent {

  constructor(public themeService: ThemeService,
    private matIconRegistry: MatIconRegistry,
    private domSanitizer: DomSanitizer,
  ) {
    this.matIconRegistry.addSvgIcon(
      "su_dashboard",
      this.domSanitizer.bypassSecurityTrustResourceUrl("../assets/icons/sidenav/dash.svg")
    );
    this.matIconRegistry.addSvgIcon(
      "su_scan",
      this.domSanitizer.bypassSecurityTrustResourceUrl("../assets/icons/sidenav/scans.svg")
    );
    this.matIconRegistry.addSvgIcon(
      "su_folder",
      this.domSanitizer.bypassSecurityTrustResourceUrl("../assets/icons/sidenav/folder.svg")
    );
    this.matIconRegistry.addSvgIcon(
      "su_training",
      this.domSanitizer.bypassSecurityTrustResourceUrl("../assets/icons/sidenav/training.svg")
    );
    this.matIconRegistry.addSvgIcon(
      "su_unlimited",
      this.domSanitizer.bypassSecurityTrustResourceUrl("../assets/icons/sidenav/unlimited.svg")
    );
    this.matIconRegistry.addSvgIcon(
      "su_community",
      this.domSanitizer.bypassSecurityTrustResourceUrl("../assets/icons/sidenav/community.svg")
    );
    this.matIconRegistry.addSvgIcon(
      "su_setting",
      this.domSanitizer.bypassSecurityTrustResourceUrl("../assets/icons/sidenav/settings.svg")
    );
    this.matIconRegistry.addSvgIcon(
      "su_dark_mode",
      this.domSanitizer.bypassSecurityTrustResourceUrl("../assets/icons/sidenav/dark mode.svg")
    );
    this.matIconRegistry.addSvgIcon(
      "su_light_mode",
      this.domSanitizer.bypassSecurityTrustResourceUrl("../assets/icons/sidenav/light mode.svg")
    );
    this.matIconRegistry.addSvgIcon(
      "su_notification",
      this.domSanitizer.bypassSecurityTrustResourceUrl("../assets/icons/toolbar/notifications.svg")
    );
    this.matIconRegistry.addSvgIcon(
      "su_help",
      this.domSanitizer.bypassSecurityTrustResourceUrl("../assets/icons/toolbar/help.svg")
    );
    this.matIconRegistry.addSvgIcon(
      "su_delete",
      this.domSanitizer.bypassSecurityTrustResourceUrl("../assets/icons/folders/delete.svg")
    );
    this.matIconRegistry.addSvgIcon(
      "su_image",
      this.domSanitizer.bypassSecurityTrustResourceUrl("../assets/icons/folders/image.svg")
    );
    this.matIconRegistry.addSvgIcon(
      "su_chart",
      this.domSanitizer.bypassSecurityTrustResourceUrl("../assets/icons/folders/price_history.svg")
    );
    this.matIconRegistry.addSvgIcon(
      "su_add_comment",
      this.domSanitizer.bypassSecurityTrustResourceUrl("../assets/icons/folders/memo.svg")
    );
    this.matIconRegistry.addSvgIcon(
      "su_search",
      this.domSanitizer.bypassSecurityTrustResourceUrl("../assets/icons/folders/search.svg")
    );
    this.matIconRegistry.addSvgIcon(
      "su_download",
      this.domSanitizer.bypassSecurityTrustResourceUrl("../assets/icons/download.svg")
    );
    this.matIconRegistry.addSvgIcon(
      "su_edit",
      this.domSanitizer.bypassSecurityTrustResourceUrl("../assets/icons/edit.svg")
    );
    this.matIconRegistry.addSvgIcon(
      "su_archive",
      this.domSanitizer.bypassSecurityTrustResourceUrl("../assets/icons/archive.svg")
    );
    this.matIconRegistry.addSvgIcon(
      "su_info",
      this.domSanitizer.bypassSecurityTrustResourceUrl("../assets/icons/info.svg")
    );
    this.matIconRegistry.addSvgIcon(
      "su_settings",
      this.domSanitizer.bypassSecurityTrustResourceUrl("../assets/icons/settings.svg")
    );
    this.matIconRegistry.addSvgIcon(
      "su_favourite",
      this.domSanitizer.bypassSecurityTrustResourceUrl("../assets/icons/favourite.svg")
    );
    this.matIconRegistry.addSvgIcon(
      "su_launch",
      this.domSanitizer.bypassSecurityTrustResourceUrl("../assets/icons/launch.svg")
    );
    this.matIconRegistry.addSvgIcon(
      "su_lock",
      this.domSanitizer.bypassSecurityTrustResourceUrl("../assets/icons/lock.svg")
    );
    this.matIconRegistry.addSvgIcon(
      "su_filter",
      this.domSanitizer.bypassSecurityTrustResourceUrl("../assets/icons/filter.svg")
    );
    this.matIconRegistry.addSvgIcon(
      "su_amazon",
      this.domSanitizer.bypassSecurityTrustResourceUrl("../assets/icons/scans/amazon.svg")
    );
    this.matIconRegistry.addSvgIcon(
      "su_calculator",
      this.domSanitizer.bypassSecurityTrustResourceUrl("../assets/icons/scans/calculator.svg")
    );
    this.matIconRegistry.addSvgIcon(
      "su_powerup",
      this.domSanitizer.bypassSecurityTrustResourceUrl("../assets/icons/scans/powerups.svg")
    );
  }

}